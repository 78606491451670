/* 2.18 - Tab - Start
================================================== */
.tab_block_wrapper {
	display: flex;
	flex-direction: column;
	.nav {
		gap: 3px;
		padding: 10px;
		align-self: center;
		margin-bottom: 60px;
		background-color: #CCE3FF;
		border-radius: var(--bs-border-radius);
		.nav-link {
			display: flex;
			line-height: 1;
			font-size: 18px;
			font-weight: 500;
			border-radius: 5px;
			align-items: center;
			color: var(--bs-dark);
			padding: 7px 30px 7px;
			justify-content: center;
			font-family: 'Axiforma Medium';
			border: 0;
			cursor: pointer;
			&.active {
				background-color: var(--bs-white);
				box-shadow: 0 4px 4px 0 rgba(174, 191, 210, 0.3);
			}
		}
	}
}
/* 2.18 - Tab - End
================================================== */