/* 2.13 - Rating Star - Start
================================================== */
.rating_block {
  gap: 2px;
  li {
    line-height: 1;
    font-size: 14px;
    i {
      color: #FCC640;
    }
  }
}
/* 2.13 - Rating Star - End
================================================== */