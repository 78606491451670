ul.smothscroll {
  position: fixed;
  bottom: 25px;
  right: 30px;
  list-style: none;
  z-index: 99;
}

ul.smothscroll a {
  z-index: 1;
  width: 40px;
  height: 40px;
  display: block;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 4px 7px rgba(178, 190, 205, 0.29);
  color: var(--color-white);
  border-radius: 50%;
  font-size: 14px;
}

ul.smothscroll a:hover {
  margin-bottom: 4px;
}

@media(max-width:767px) {
  ul.smothscroll a {
      width: 30px;
      height: 30px;
      line-height: 25px;
  }
  
}
@media(max-width:767px) {
  
  .business_consulting_hero_section {
    padding-top: 200px !important;
  }
  .business_consulting_hero_content {
    text-align: center;
  }
  .center-circle .center-title img {
    width: 125px !important;
  }
}
.iconbox_block:hover {
  transform: scale(1.2);
}
.new.tab_block_wrapper .nav {
  background: white;
  align-self: start;
  border-bottom: none;
}

.new.tab_block_wrapper .nav .nav-item .nav-link.active {
  border: 1px dashed #000;
}
.position-relative1 {
  overflow: hidden;  /* Prevents the logo from moving outside the column */
}

/* Animation class */
.translate-loop {
  display: inline-block;
  animation: translateLR 2s ease-in-out infinite; /* Apply animation */
}

@keyframes translateLR {
  0% {
    transform: translateX(-10px); /* Start off to the left */
  }
  50% {
    transform: translateX(10px); /* Move to the right */
  }
  100% {
    transform: translateX(-10px); /* Return to the left */
  }
}


.technology-section {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-circle {
  position: absolute;
  /* width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #ffffff; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  /* padding: 10px; */
  text-align: center;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.center-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.outer-circle {
  position: relative;
  width: 500px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tech-item {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tech-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.tech-title {
  font-size: 0.85rem;
  color: #333;
}

.outer-circle .tech-item:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.outer-circle .tech-item:nth-child(2) {
  top: 25%;
  left: 90%;
  transform: translate(-50%, -50%);
}

.outer-circle .tech-item:nth-child(3) {
  top: 75%;
  left: 90%;
  transform: translate(-50%, -50%);
}

.outer-circle .tech-item:nth-child(4) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.outer-circle .tech-item:nth-child(5) {
  top: 75%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.outer-circle .tech-item:nth-child(6) {
  top: 25%;
  left: 10%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
.technology-section {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-circle {
  position: absolute;
  width: 160px;
  top: 40%;
  /* height: 100px; */
  border-radius: 12px;
  /* background-color: #ffffff; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  /* padding: 10px; */
  text-align: center;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.center-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.outer-circle {
  position: relative;
  width: 325px;
  height: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tech-item {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tech-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}
.web_development_technologies .iconbox_block .iconbox_title {
  font-size: 15px !important;
}

}  

@keyframes zoom-effect {
  0%, 100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.05); /* Slight zoom-out */
  }
}
.header_bottom {
  z-index: 1000; /* Should be less than the modal's z-index */
}

/* ///////
 */

 .center-circle .center-title img {
  width: 200px;
  padding: 10px;
  background: white;
  border-radius: 20px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.outer-circle .tech-item:nth-child(6) img.arrow {
  position: absolute;
  left: 73%;
  bottom: -39%;
  width: 94px;
  z-index: 0;
  transform: rotate(18deg);
}
.outer-circle .tech-item:nth-child(5) img.arrow {
  position: absolute;
  left: 83%;
  bottom: 48%;
  width: 94px;
  z-index: 0;
  transform: rotate(293deg);
}
.outer-circle .tech-item:nth-child(4) img.arrow {
  position: absolute;
  left: 19%;
  top: -123%;
  width: 94px;
  z-index: 0;
  transform: rotate(257deg);
}
.outer-circle .tech-item:nth-child(3) img.arrow {
  position: absolute;
  left: -81%;
  bottom: 82%;
  width: 94px;
  z-index: 0;
  transform: rotate(186deg);
}

.outer-circle .tech-item:nth-child(2) img.arrow {
  position: absolute;
  left: -95%;
  top: 26%;
  width: 170px;
  z-index: 0;
  transform: rotate(128deg);
}
.outer-circle .tech-item:nth-child(1) img.arrow {
  position: absolute;
  left: 0;
  bottom: -126%;
  width: 94px;
  z-index: 0;
  transform: rotate(65deg);
}
.successImage {
  max-width: 250px;
}