/* 4.01 - Blog - Start
================================================== */
.blog_section_space {
	padding-top: 125px;
	padding-bottom: 75px;
}

.blog_section {
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center center;

	.shape_image_1 {
		right: 0;
		bottom: 0;
	}

	.shape_image_2 {
		left: 0;
		bottom: 0;
		max-width: 265px;
	}
}

.blog-page {

	.blog_post_block {
		.blog_post_image {
			.image_wrap {
				img {
					min-height: 370px;
					object-fit: cover;

					@media(max-width:991px) {
						min-height: 100%;
					}
				}
			}
		}
	}
}

// Blog Post Block
.blog_post_block {
	overflow: hidden;
	transition: var(--bs-transition);
	background-color: var(--bs-white);
	border-radius: var(--bs-border-radius-sm);
	box-shadow: 0 1px 2px 0 rgba(174, 191, 210, 0.3);

	&:hover {
		box-shadow: 0 20px 30px 0 rgba(174, 191, 210, 0.3);
	}

	.blog_post_image {
		.image_wrap {
			display: block;
			overflow: hidden;
			position: relative;

			img {
				transition: var(--bs-transition);
			}

			&:hover {
				img {
					transform: scale(1.108);
				}
			}

			&:has(i) {
				&:before {
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					opacity: 0;
					z-index: 1;
					content: '';
					transition: .2s;
					position: absolute;
					background-color: rgba(2, 8, 66, 0.4);
				}
			}
		}

		i {
			top: 50%;
			left: 50%;
			z-index: 2;
			width: 100px;
			height: 100px;
			font-size: 30px;
			position: absolute;
			align-items: center;
			border-radius: 100%;
			display: inline-flex;
			color: var(--bs-white);
			justify-content: center;
			background-color: var(--bs-primary);
			transform: translate(-50%, -50%) scale(0);
			transition: transform var(--bs-transition);
		}
	}

	&:hover {
		.image_wrap {
			&:before {
				opacity: 1 !important;
			}

			i {
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}

	.blog_post_content {
		padding: 40px 30px;
	}

	.post_meta_wrap {
		gap: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		.category_btns_group {
			gap: 3px;
		}

		.post_meta {
			margin-top: 4px;
		}
	}

	.blog_post_title {
		font-size: 22px;
		font-weight: 600;
		line-height: 32px;
		margin: 24px 0 30px;
		letter-spacing: -0.3px;
		font-family: 'Axiforma SemiBold';

		a {
			color: var(--bs-dark);

			&:hover {
				color: var(--bs-primary);
			}
		}
	}
}

.blog_post_block.layout_2 {
	box-shadow: none;
	border-radius: 0;
	background-color: transparent;

	.blog_post_image .image_wrap {
		border-radius: var(--bs-border-radius);
	}

	.post_meta {
		gap: 30px;
		margin: 15px 0 0;
		padding: 20px 0 0;
		border-top: 1px solid var(--bs-border-color);
	}
}

.blog_post_block.image_left_layout {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 30px;
	}

	.blog_post_image {
		width: 312px;
		flex: 0 0 auto;
	}

	.blog_post_content {
		flex: 1;
		padding: 40px;
	}

	.blog_post_title {
		font-size: 26px;
		line-height: 35px;
		margin: 20px 0 14px;
	}

	p {
		margin-bottom: 30px;
	}

	.btn {
		padding: 0 26px;

		.btn_label {
			padding: 15px 0px 13px;
		}
	}
}

// Blog Over Content Layout
.blog_onecol_carousel {
	position: relative;

	[class*="b1cc-swiper-button-"] {
		top: 50%;
		z-index: 2;
		width: 28px;
		display: flex;
		height: 158px;
		text-align: center;
		position: absolute;
		align-items: center;
		justify-content: center;
		background-size: 100% 100%;
		transform: translateY(-50%);
		background-repeat: no-repeat;
		background-position: center center;

		&:hover {
			color: var(--bs-primary);
		}
	}

	.b1cc-swiper-button-prev {
		left: -1px;
	}

	.b1cc-swiper-button-next {
		right: -1px;
	}

	.b1cc-swiper-pagination {
		right: 50px;
		bottom: 50px;
		left: auto;
		z-index: 1;
		width: auto;
		position: absolute;

		.swiper-pagination-bullet {
			background-color: var(--bs-white);
		}
	}
}

.blog_post_block.content_over_layout {
	position: relative;

	.image_wrap {
		&:before {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			content: '';
			position: absolute;
			background-image: linear-gradient(0deg, var(--bs-primary), transparent);
		}
	}

	.blog_post_content {
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		position: absolute;
		padding: 50px 50px 40px;
	}

	.blog_post_title {
		font-size: 45px;
		line-height: 58px;
		margin: 24px 0 15px;

		a {
			color: var(--bs-white);

			&:hover {
				opacity: 0.8;
			}
		}
	}

	p {
		font-size: 16px;
		max-width: 699px;
		line-height: 28px;
		color: var(--bs-white);
	}

	.category_btns_group a {
		border-color: var(--bs-white);
		background-color: var(--bs-white);

		&:hover {
			color: var(--bs-primary);
		}
	}

	.post_meta>li a {
		color: var(--bs-white);

		i {
			color: var(--bs-white);
		}
	}
}

// Blog Post Block 2
.blog_section_2 {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.blog_post_block_2 {
	display: flex;
	position: relative;
	align-items: center;

	.blog_post_image {
		order: 1;

		>a {
			display: block;
			overflow: hidden;
			position: relative;
			border-radius: 32px;
		}

		img {
			transition: var(--bs-transition);
		}
	}

	.blog_post_content {
		z-index: 1;
		max-width: 340px;
		position: relative;
		margin: 0 -180px 0 0;
		border-radius: 16px;
		padding: 60px 30px 52px;
		background-color: var(--bs-white);
		box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
	}

	.category_list {
		>li {
			font-size: 14px;
			font-weight: 700;
			font-family: 'Axiforma Bold';

			a:not(:hover) {
				color: var(--bs-dark);
			}
		}
	}

	.post_title {
		font-size: 30px;
		line-height: 38px;
		margin: 22px 0 14px;

		a {
			color: var(--bs-dark);

			&:hover {
				text-decoration: underline;
				text-decoration-thickness: 2px;
			}
		}
	}

	.post_meta {
		row-gap: 6px;
	}

	&:hover {
		.blog_post_image {
			img {
				transform: scale(1.08);
			}
		}

		.post_title {
			>a {
				color: var(--bs-primary);
			}
		}
	}
}

// Post List Block
.post_list_block {
	>ul {
		gap: 20px;

		>li {
			padding: 26px 30px 22px;
			background-color: var(--bs-white);
			border-radius: var(--bs-border-radius);
			box-shadow: 0 20px 30px 0 rgba(174, 191, 210, 0.3);

			.post_title {
				font-size: 18px;
				line-height: 26px;
				margin-bottom: 16px;

				>a {
					color: var(--bs-dark);

					&:hover {
						color: var(--bs-primary);
					}
				}
			}

			&:hover {
				.post_title {
					>a {
						text-decoration: underline;
					}
				}
			}
		}

	}
}

/* 4.01 - Blog - End
================================================== */